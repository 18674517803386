/* Background overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dimming effect */
  filter: brightness(0.4); /* Optional for extra brightness control */
  z-index: 9998;
}
/* Centered Notification Container */
.notification-container {
  position: fixed;
  top: 30%;
  left: 32%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 35vw;
  padding: 35px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 8px;
  background: #FFF;
  /* lndex drop shadow */
  box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
  z-index: 9999;
}
 .notification-content {
  display: flex;
  padding: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  }
  .notification-message {
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: 0.8px;
    text-transform: capitalize;
    color: #18172F;
  }
  
  .notification-close-button {
    background: none;
    border: none;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: 2px;
    padding: 5px;
  }
  
  .notification-close-button:hover {
    color: #240606;
  }
  
  .notification_buttons {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
   .notification_buttons_Update {
    display: flex;
    width: 15vw;
    height: 40px;
    padding: 8px 8spx;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /* style text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
   }
   .notification_buttons_NotNOW {
    display: flex;
    width: 15vw;
    height: 40px;
    padding: 8px 8spx;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #EDEFFF;;
    border: none;
    /* style text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
   }
   .popupalert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    /* Style Text */
    color: #18172F;
    text-align: center;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 45px */
    letter-spacing: -0.57px;
    text-transform: capitalize;
   }