@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Container styling */
.Edit_Instructor_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9fc;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Heading styling */
  .Edit_Instructor_Container h2 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Form styling */
  .edit-profile-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Label styling */
  .edit-profile-form label {
    font-size: 16px;
    color: #555;
    margin-bottom: 8px;
    margin-top: 25px;
  }
  
  /* Input and textarea styling */
  .InstructorEdit_form_Section_input {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    width: 100%;
    color: #333;
    background-color: #EDEFFF;
    transition: border 0.3s ease, background-color 0.3s ease;
  }
  
  .InstructorEdit_form_Section_input:focus {
    outline: none;
    border-color: #6a8efb;
    background-color: #e0e7ff;
  }
  
  /* Textarea specific styling */
  textarea.InstructorEdit_form_Section_input {
    resize: vertical;
    min-height: 100px;
  }
  
  /* Style for the loading and error messages */
  .Edit_Instructor_Container p {
    font-size: 14px;
    color: #ff4d4f;
    text-align: center;
    margin: 5px 0;
  }
  
  /* Small spacing adjustments */
  .edit-profile-form label + .InstructorEdit_form_Section_input,
  .edit-profile-form label + textarea.InstructorEdit_form_Section_input {
    margin-top: 8px;
  }
  .InstructorEdit_form_submit_button_container {
    margin-top: 25px;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .InstructorEdit_form_submit_button {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    width: 15vw;
    background: #18172F;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    position: relative; /* تأكد من أن الزر لديه موقع نسبي */
  }
  

.address-input {
  display: flex;
  flex-direction: column;
  
}