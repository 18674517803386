@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --main-color: #18172F;
    --font-family: 'Inter', sans-serif;
    --font-size: 14px;
    --font-style: normal;
    --font-weight: 500;
    --line-height: 1.5; /* أو 150% */
    --letter-spacing: -0.304px;
  }





.UserPage_Continer {
    display: flex;
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
}
  /* User Header */
.UserPage_Header {
    display: flex;
    padding: 10px 0px;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    width: 100%;
    /* Stele the text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
/* Style Section One */
.UserPage_Sec1 {
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.UserPage_Sec1_Part1 {
    display: flex;
    height: 148px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* Image Background */
    border-radius: 9px;
    background: url('../../assets/images/instructors/InstructorBackground.svg') lightgray 50% / cover no-repeat ;
}
.UserPage_Sec1_Part2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}
.UserPage_Sec2 {
    display: flex;
    width: 345px;
    padding: 15px 0px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}
.UserImage {
    width: 148px;
    height: 148px;
    /* lndex drop shadow */
    border-radius: 50%; /* Example for circular images */
    display: block;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.UserPage_Sec2_Part1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Parent container needs relative positioning */
    display: inline-block; /* Ensures the UserImage and CameraIcon stay together */
}
.user_CameraIcon {
    position: absolute;
    bottom: 0px; /* Adjust based on desired placement */
    right: 5px;  /* Adjust based on desired placement */
    width: 48px;  /* Example size for the icon */
    height: 48px;
    cursor: pointer; /* Optional: indicates it's clickable */
  }
  .UserPage_Sec2_part2_email {
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: var(--font-style);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
  }
.UserPage_Sec2_part2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.UserPage_Sec2_part2_Button{
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: none;
    background: #3F52FC;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
    cursor: pointer;
}
.UserPage_Sec2_part2_name {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.UserPage_Sec2_part2_Major {
    color: #5E6EFD;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.UserPage_Sec2_part2_JoinDate {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.UserPage_Sec3{
    display: flex;
    height: 163px;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.UserPage_Sec3_Part1 {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-decoration-line: underline;
    text-transform: capitalize;
    cursor: pointer;
}
.UserPage_Sec3_Part2{
    display: flex;
    padding: 0px 24px;
    align-items: flex-end;
    gap: 56px;
    border-radius: 8px;
}
.UserPage_Sec3_Part2_item {
    display: flex;
    width: 250px;
    align-items: center;
    gap: 15px;
    /* number */
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
.UserPage_Sec3_Part2_item span {
    color: #18172F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.UserPage_Sec4{
    display: flex;
    align-items: flex-start;
    gap: 58px;
    align-self: stretch;
}
.UserPage_Sec4{
    display: flex;
align-items: flex-start;
gap: 58px;
align-self: stretch;
/* color */
color: #7E7E8C;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: -0.38px;
text-transform: capitalize;
cursor: pointer;
}

.UserPage_Sec4 > .active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    border-bottom: 5px solid #3F52FC;
    padding-bottom: 10px;
  }
.UserPage_Sec5 {
    display: flex;
align-items: flex-start;
align-content: flex-start;
gap: 40px;
align-self: stretch;
flex-wrap: wrap;
}
/*Logout button*/
.Logout_button {
    display: flex;
    /*padding: 10px 20px 10px 24px;*/
    padding: 10px;
    align-items: center;
    gap: 2px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; /* 24px */
    letter-spacing: -0.304px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    overflow: hidden;
   /* margin-top: 25px;*/
}
.Logout_button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease;
    border-radius: 50%;
    z-index: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  .Logout_button:hover::before {
    transform: translate(-50%, -50%) scale(1);
    
  }
  
  .Logout_button:hover {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  
  .Logout_button:active {
    transform: translateY(2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }