/* Container */
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    text-align: center;
    font-family: 'Arial, sans-serif';
    color: #333;
  }
  
  /* Content Box */
  .error-content {
    max-width: 600px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* 404 Heading */
  .error-content h1 {
    font-size: 120px;
    margin: 0;
    color: #ff5e62; /* Red/Orange Gradient */
    background: -webkit-linear-gradient(45deg, #ff5e62, #ff9966);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Subheading */
  .error-content h2 {
    font-size: 28px;
    margin: 20px 0 10px;
  }
  
  /* Message */
  .error-content p {
    font-size: 18px;
    margin: 0 0 20px;
    color: #777;
  }
  
  /* Home Button */
  .home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background: #ff5e62;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
  }
  
  .home-button:hover {
    background: #ff9966;
  }
  