.search_course_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 25px;

}

.search_course_card {
    display: flex;
    width: 316px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 12px;
    cursor: pointer;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
   }
    /* Course Image */
   .search_course_card_img {
    background: lightgray 50% / cover no-repeat, #EDEFFF;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    height: 186px;
   }

   .search_course_card_Content {
     display: flex;
     padding: 8px 16px;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     gap: 8px;
     align-self: stretch;
    /* Text Style */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
   }

