.faqs-container {
    padding: 20px;
    background-color: #EDEFFF;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #EDEFFF;
    max-height: 400px; /* يمكنك تعديل هذا الطول حسب الحاجة */
    overflow-y: auto; /* يضيف شريط تمرير عمودي إذا كان المحتوى يتجاوز max-height */
    overflow-x: hidden; /* يزيل شريط تمرير أفقي إذا كان غير مطلوب */
  }
  
  .faqs-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .faq-input,
  .faq-textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  .faq-component-container {
    display: flex;
    justify-content: flex-end;
  }
  .faq-add-button {
    display: flex;
    padding: 8px 24px 8px 16px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 6px;
    background: #18172F;
    width:250px;
    /* Text Style */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
  }
  
  .faq-add-button:hover {
    background: #18172F;
  }
  
  .faqs-list {
    list-style: none;
    padding: 0;
  }
  
  .faq-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .faq-remove-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .faq-remove-button:hover {
    background-color: #c0392b;
  }
  
  .fag-delete-icon {
    cursor: pointer;
  }