@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
/* The Sidebar Container */
.Sidebar_container{
display: flex;
width: 264px;
background: #F8F9FF;
padding: 40px 24px;
flex-direction: column;
justify-content: space-between;
gap: 10px;
align-items: flex-start;
flex-shrink: 0;
position: fixed;
height: 100vh;
/*z-index: 0;**/

}
.Sidebar_Sec1{
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 gap: 50px;
 align-self: stretch;
}
/* To style List in Sidebar */
.Sidebar_Part1_List {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.Sidebar_Part1_List ul{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    padding: 0;
    margin: 0;
}
.Sidebar_Part1_List ul li {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
}
.Sidebar_Part1_List ul li.active {
    padding-left: 15px;
    opacity: 1;

    border-left: 4px solid #007bff; 
  }
.Sidebar_Part1_List ul li:hover {
   padding-left: 15px;
   opacity: 1;
}
   /* style footer at sidebar */
.Sidebar_Footer{
    color: #7280FE;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.023%; /* 22.404px */
}   

.cart_item {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 50px;
    background: #FF5353;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
   }