@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Category_List_Container {
    display: inline-flex;
    padding: 16px 4px;
    align-items: flex-start;
    gap: 18px;
    border-radius: 8px;
    background: #18172F;

}
.Category_List_Container li{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    list-style: none;
    cursor: pointer;
    
}
.Category_List_Container li:hover{
text-decoration: underline;
}





.Category_List_Section_one ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    padding: 5px;
}