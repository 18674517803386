@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --text-color: #000;
    --font-family: 'Inter', sans-serif;
    --font-size: 20px;
    --font-weight: 500;
    --line-height: 150%;
    --letter-spacing: -0.38px;
}

.EnterpriseInformation_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    width: 100%;
    max-width: 100%;
    padding-left: 20%;
    padding-right: 20%;
}
/* Styles specific to Arabic */
.EnterpriseInformation_Container.ar {
    text-align: right; /* Align text to the right */
}

.EnterpriseInformation_Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding-top: 25px;
}
/* Styles specific to Arabic */
.EnterpriseInformation_Section.ar {
    align-items: flex-end; /* Align items to the right */
}

.EnterpriseInformation_Section {
    color: var(--text-color);
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-style: normal;
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
}
.EnterpriseInformation_Section input {
    display: flex;
    width: 704px;
    padding: 7px 47px;
    align-items: center;
    gap: 10px;
    border: none;
    font-size: 16px;
    border-radius: 10px;
    background: #EDEFFF;
    outline: none;
}
.EnterpriseInformation_textTitle {
    display: flex;
    width: 316px;
    height: 57px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: rgba(63, 82, 252, 0.50);
    /* Style Text */
    color: #121744;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}

.required {
    color: red; /* تعيين اللون الأحمر */
}
/* Design imagePreview  */
.EnterpriseInformation_Section_image-preview{
    display: flex;
    width: 350px;
    height: 350px;
    padding: 7px 47px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #EDEFFF;
}

.EnterpriseInformation_Section_Button button{
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 50px;
    border-radius: 6px;
    border-radius: 6px;
    background: #18172F;
    /* style color save button */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}

.Enterprise_Delete_Account {
    position: absolute;
    cursor: pointer;
    bottom: -60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 16px 24px;
    border-radius: 8px;
    background: #FFF;
    border: none;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    /* Style Color */
    color: #FF5353;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 24px */
    letter-spacing: -0.304px;
}

/* images with main style */
.EnterpriseInformation_Section {
    text-align: center;
  }
  
  .main-image img {
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .thumbnail img {
    width: 150px;
    height: 90px;
    object-fit: cover;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .thumbnail img:hover {
    border: 2px solid #007bff;
  }
  
  .thumbnail button {
    display: block;
    margin-top: 5px;
    padding: 5px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
  }
  .spinner {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #007bff;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .EnterpriseInformation_Enterprise_Support {
    display: flex;
    padding: 22px 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #EDEFFF;
  }
  .InstructorHome_AddInstructor_Button {
       display: flex;
        padding: 8px 16px 8px 16px;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        border-radius: 6px;
        width: 180px;
        max-height: 50px;
        background: #3F52FC;
        position: absolute;
        border: none;
        left: 80%;
        top: 2%;
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.304px;
        text-transform: capitalize;
  }