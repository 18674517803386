@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorAbout_Container { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    width: 100%;
}
.InstructorAbout_Sec1 {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.InstructorAbout_Sec2{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
}
.InstructorAbout_Sec2_Part1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}
.InstructorAbout_Sec2_Part1_ParghraphOne {
    width: 604px; 
   /* Text Style */
   color: #18172F;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 150%; /* 24px */
   letter-spacing: -0.304px;
   text-transform: capitalize;   
}