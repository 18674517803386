/* Base Styles (Desktop or Larger Screens) */
body {
    padding:0px;
    margin: 0px;
}
.Create_Article_Container {
    display: inline-flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    width: 100%;
}

.Create_Article_Form_Add {
    display: flex;
    padding-bottom: 64px;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 80%;
}

.Publish_Article {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

/***********  Upload Image      ******************/

.upload-Container  {
    border: 2px solid #e5e5f7;
    border-radius: 8px;
    width: 350px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0ff !important;
    position: relative;
    cursor: pointer;
  }
  .placeholder {
    text-align: center;
    background-color: #f0f0ff;
  }
  .text {
    font-size: 18px;
    color: #5c5ce0;
    margin: 10px 0;
  }
  .subtext {
    font-size: 16px;
    color: #9a9a9a;
  }
  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .InstructorEdit_form_Section_ReactQuill {
    /* border: 1px solid #ccc;*/
     border-radius: 8px;
     color: #000;
     width: 100%;
     height: 100%;
    /* background-color: #f0f0ff ;*/
     padding-bottom: 20px;
  
   }
  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .Article_added_Alert {
     display: flex;
     width: 25vw;
     background-color: #5c5ce0;
     justify-content: center;
     padding: 4vh 0;
     filter: opacity(0.7);
     position: absolute;
     top: 40%;
     color: #fff;
     font-size: large;
     font-weight: 600;
     display: none;
  }
  
/* Media Query for Medium Screens (Tablets, etc.) */
@media (max-width: 768px) {
    .Create_Article_Container {
        padding-bottom: 100px; /* Adjust padding for smaller screens */
        gap: 40px; /* Reduce gap */
    }

    .Create_Article_Form_Add {
        width: 90%; /* Increase width to use more space */
        gap: 25px; /* Reduce gap between form elements */
    }

    .Publish_Article {
        justify-content: center; /* Center the publish button on medium screens */
    }
    .InstructorCreateCourse_Sec1 {
        font-size: 4vw;
    }
}

/* Media Query for Small Screens (Mobile Devices) */
@media (max-width: 480px) {
    .Create_Article_Container {
        padding-bottom: 0px;
        gap: 30px; /* Further reduce gap on smaller screens */
    }

    .Create_Article_Form_Add {
        width: 100%; /* Use full width on small devices */
        padding-bottom: 40px; /* Adjust padding */
        gap: 20px; /* Reduce gap even further */
    }

    .Publish_Article {
        justify-content: center; /* Keep button centered on small screens */
    }
    .InstructorCreateCourse_form_Section {
      width: 100%;
    }
    .InstructorCreateCourse_form_Section_input {
        width: 350px;
    }
}
