html, body {
    margin: 0;
    padding: 0;
   /* height: 100%;*/
    overflow-x: hidden !important; /* Prevent vertical scrolling */
    scroll-behavior: auto; /* يمكن تغييره إلى smooth عند الحاجة */
  }

  body {
    font-family: 'Fustat', sans-serif;
  }
  .App { /* or the parent container of Sidebar_container */
   
    display: flex; /* optional: if you want to use flexbox layout for parent */
  }
  #root {
    height: 100%;
    min-height: 100vh;
  }
  