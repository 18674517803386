.usernavbar_Container {
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70vw;
}
.usernavbar_Leftside{
display: flex;
gap: 10px;
}
.usernavbar_Rightside {
  display: flex;
  gap:10px;
}
.UnauthAppbar_Second_app_button{
  display: flex;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 24px */
  letter-spacing: -0.304px;
  border-radius: 6px;
  background: #3F52FC;
  border: none;
}
.User_Search{
    display: flex;
    width: 284px;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 6px 6px 6px 6px;
    background: #EDEFFF;
    /* Font style inside the input Field */
    color: #353646;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    cursor: pointer;
    border:none;
}
.Search:focus {
    border : none ;
    outline: none; /* Remove default outline */
}
  /*
.Search_ar:focus {
    border: none !important;
  } */
  
.Search::placeholder{
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.search-icon {
    position: absolute;
    right: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures the icon doesn't block input interactions */
    height: 20px; /* Adjust icon size as needed */
    width: 20px; /* Adjust icon size as needed */
    cursor: pointer;
  }

  /** Category Section  **/


  /* Container for the button and NavbarCategory */
.categories-container {
  position: relative; /* Set container position to relative */
  display: inline-block; /* Keep the button and dropdown aligned */
}

/* Wrapper for NavbarCategory */
.navbarcategory-wrapper {
  position: absolute; /* Position relative to the container */
  top: 100%; /* Position right below the button */
  left: 0; /* Align to the left of the button */
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  border-radius: 4px; /* Optional border radius */
 /* padding: 10px; /* Optional padding */
  padding-top: 12px;
  width: max-content; /* Adjust width as needed */
}

/* Optional: Adjust the button styling for visual feedback */
.UnauthAppbar_Second_app_button {
  position: relative;
  z-index: 11; /* Ensure the button is above the dropdown */
}

.PolygonCategory {
  position: absolute; /* Position relative to the container */
  top: 0%; /* Position right below the button */
  left: 20%; /* Align to the left of the button */
  z-index: 10; /* Ensure it appears above other elements */
}