.Article_container_Dashboard {
    width: 350px;
    max-height:600px;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    border-radius: 16px 16px 16px 16px;
    gap: 8px;
}
.Unarchived_Article{
    display: flex;
    width: 350px;
    max-height:600px;
   /* height: 496px;*/
    flex-direction: column;
    align-items: center;
    /*gap: 8px;*/
    box-shadow: 0px 10px 10px 5px #EDEFFF;
    border-radius: 16px 16px 16px 16px;
    cursor: pointer  ;
   /* filter: brightness(50%);*/ 
    filter: grayscale(70%);
}