.slider-container {
    position: relative;
  }
  
  .slider-container .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
  }
  
  .slider-container .arrow:hover {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .slider-container .indicator {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .slider-container .indicator div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
  }
  
  .slider-container .indicator div.active {
    background-color: #007bff;
    transform: scale(1.4);
    transition: all 0.4s ease-in-out;
  }
  