@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.UpdateProfile_radio_buttons {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.update_Label_active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
.update_Label_Non_active {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
            /*  Style ChangePassword   */
.ChangeYourPassword_Container {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 500px;
    
}
.Chang_Pass_Sec {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  
}
.Chang_Pass_Sec_label{
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Chang_Pass_Buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
/* Button Save change Password */
.Chang_Pass_Buttons_Save {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /*text in button*/
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Chang_Pass_Buttons_Forget {
    color: #3F52FC;    
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-decoration-line: underline;
    cursor: pointer;
}