@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorNav_Container {
    display: flex;
    width: 100%;
    padding: 40px 40px 24px 40px;
    justify-content: space-between;
    align-items: flex-start;
}
.InstructorNav_Logo{
    cursor: pointer;
}
.InstructorNav_RightSide{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
}
.InstructorNav_LanguageButton{
    display: flex;
    width: 121px;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #EDEFFF;
    border: none;
    /* Text Style */
    color: #18172F;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
/* Style Logout Button */
.InstructorNav_LogoutButton {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 6px;
    background: #3F52FC;
    /* style text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
}