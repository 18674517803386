.mobileNavbar_Container {
    display: flex;
    justify-content: space-between; 
    margin-bottom: 5px;
    position: relative;
    top: 0%;
    z-index: 1001;
}
.mobileNavbar_Sec1 {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    z-index: 1001;
    background-color: aliceblue;
    width: 100%;
    height: 100%;
    padding: 10px;
}
.mobileNavbar_Logo {
    width: 120px;
}
.mobileNavbar_sidebar {
    position: fixed;
    top: 0;
    left: -100%; /* Initially hide the sidebar outside the viewport */
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255); /* Background color with opacity */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1000;
    transition: left 0.3s ease; /* Transition for sliding effect */
  }
  
  .mobileNavbar_sidebar.visible {
    left: 0; /* Move sidebar into the viewport */
  }
  .mobileNavbar_sidebar_content {
    display: flex;
    flex-direction: column;
    gap: 30px ;
    padding-top: 100px;
    position: relative;
    left: 0;
    list-style-type: none ;
    width: 100%;
    /* Style The Text */
    color: #5E6EFD;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
  }
  .mobileNavbar_sidebar_content_Auth {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0px;
    padding-right: 40px;
  }
  .mobileNavbar_sidebar_content_Item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
