@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.SearchPage_Container{
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 44px;
    align-self: stretch;
}
.SearchPage_Sec1 {
    display: flex;
    padding: 0px 40px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* start to design Text at This Section  */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /*line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
/* Style section 2 */
.SearchPage_Sec2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.SearchPage_Sec2_Part1
{
    display: flex;
    align-items: flex-start;
    gap: 8px;
    /* Style Text */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.SearchPage_Sec2_Part1 span {
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}

.SearchPage_Sec2{
    display: flex;
    align-items: center;
    align-content: space-evenly;
    gap: 44px;
    align-self: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
}
    