.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent background */
  z-index: 9999; /* Ensure it stays above all content */
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.loading-screen.hide {
  opacity: 0;
  pointer-events: none;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-logo {
  width: 80px;
  position: absolute;
  top: 50%;
  /*margin-bottom: 20px;*/
  animation: fadeIn 1s ease-in-out;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fade-in animation for the logo */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
