@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Signup_home{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*box-shadow: 0px 0px 20px 5px #7E7E8C;*/
} 
/** home container  **/
.Home_container_AR{
    display: flex;
  /*   width: 95%; */
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
    align-items: flex-start;
  /*  gap: 88px; */  /* the default gap*/
  gap: 40px;
    /* style text */
    color: #3F52FC;
   /* leading-trim: both;*/
   /* text-edge: cap;*/
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
   /* cursor: pointer;*/
}
.Home_Sec1_AR {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: -1;
}
.Home_Sec1_Panner_AR {
    position: relative;
    width: 90vw;
}
.Home_Sec1_title_AR{
    position: absolute;
    max-width:  475px;
    right: 150px;
    top: 50px;
    direction: rtl;
    font-family: 'Fustat', sans-serif;
}
.Home_Sec1_title_h1_AR{
    font-size: 40px;
    color:#9298c9;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
}
.Home_Sec1_title_p_AR {
    font-size: 20px;
    color:#9298c9;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
}
/* sec2 home category Hash */
.Home_Sec2_AR {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 44px;
    align-self: stretch;
    padding: 24px;
}
.Home_Sec2_part1_AR{
    color: #3F52FC;
    /*font-family: Inter;*/
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */
    letter-spacing:1px;
    text-transform: capitalize;
}
/**/
.Home_Sec2_part2_AR{
    display: flex;
    padding: 30px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 15px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 16px;
    background: #EDEFFF;
    position: relative;
    z-index: -1;
    direction: rtl;
}
/* Items */
.Home_Sec2_part2_items_AR{
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 24px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    background: #FFF;
    /* lndex drop shadow */
    font-weight: 500;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    /*style text */
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.Home_Sec2_part2_items_Icon_AR {
    width: 20px;
}
.Home_Sec2_part2_items_AR:hover {
      font-weight: 100;
      cursor: pointer;
}
.Home_Sec2_part2_icon_AR{
    position: absolute;
    left: 100px;
    bottom: -8.858px;
}
.Home_Sec3_AR{
    display: flex;
    flex-direction: row-reverse;
    padding: 8px 0px;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    
}
.Home_Sec3_Part1_AR{
    color: #3F52FC;
    /*font-family: Inter;*/
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */
    letter-spacing:1px;
    text-transform: capitalize;
}
.Home_Sec3_Part2_AR{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}
.Home_Sec4_AR {
    display: flex;
    height: 600px;
    align-items: center;
    justify-content: center;
    gap: 32px;
    align-self: stretch;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Optional for smooth scrolling */
    transition: transform 0.5s ease; /* Apply transition to transform property */
    /*padding-left: 24px;*/
}
.Home_Sec4::-webkit-scrollbar{
    display: none; /* Hide the scrollbar for WebKit browsers */
}
.Home_Sec4_AR {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* End the section about Course Container  */
/* Sec5 Instrector Container  */
.Home_Sec5_AR {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 10px;
    align-self: stretch;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Optional for smooth scrolling */
    transition: transform 0.5s ease; /* Apply transition to transform property */
    padding-left: 24px;
}
.Home_Sec5::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
}
.Home_Sec5_AR {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* sec 6 about Article container */
.Home_Sec6_AR {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Optional for smooth scrolling */
    transition: transform 0.5s ease; /* Apply transition to transform property */
    padding-left: 24px;
}
.Home_Sec6::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
}
.Home_Sec6_AR {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* Start Style Section 7  about Student Review */
.Home_Sec7_AR {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 44px;
    align-self: stretch;
 
}
.Home_Sec7_Part1_AR{
display: flex;
width: 1088px;
padding: 24px;
flex-direction: column;
align-items: center;
gap: 24px;
/* start style header text */
color: #3F52FC;
font-family: Inter;
font-size: 39.06px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 58.59px */
letter-spacing: -0.742px;
text-transform: capitalize;
}
.Home_Sec7_Part2_Container_AR {
    display: flex;
    width: 900px;
    padding: 32px 24px 24px 24px;
    flex-direction: column;
    direction: rtl;
    align-items: center;
    gap: 32px;
    border-radius: 8px;
    background: #EDEFFF; 
    position: relative;
}
.Home_Sec7_Part2_Container_Text_AR {
    color: #3F52FC;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.Home_Sec7_Part2_Container_Star_AR{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
}
.Home_Sec7_Part2_Container_Student_AR{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.Home_Sec7_Part2_Container_Student_Name_AR{
    color: #18172F;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}   
.Home_Sec7_Part2_Container_Student_Role_AR{
    color: #7E7E8C;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.QouteIcon_AR {
width: 204px;
height: 172.589px;
position: absolute;
right: 19px;
bottom: -30.294px;
}
/* Start To design Section 8 (Footer) */
.Home_Sec8_Footer{
    display: flex;
    padding: 40px 80px 56px 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #F8F9FF;
    margin-top: 50px;

}
.Home_Sec8_Footer_Sec1{
    display: flex;
    align-items: center;
    gap: 8px;
    /* Style Text */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    cursor: pointer;
}
/* footer Icon */
.Home_Sec8_Footer_Sec2{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
/* Footer List */
.Home_Sec8_Footer_Sec3{
    display: flex;
align-items: center;
gap: 40px;
}
.Home_Sec8_Footer_Sec3 ul {
    display: flex;
    align-items: center;
    gap: 40px;
    /**/
    list-style-type: none;
    color: #7E7E8C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: -0.304px;
}
.Home_Sec8_Footer_Sec3 ul li:hover {
    color: #3F52FC;
}
/* Social Media IN Footer */
.Home_Sec8_Footer_Sec4{
    display: flex;
    width: 224px;
    justify-content: center;
align-items: center;
gap: 40px;
}
/* @copy Right Section */
.Home_Sec8_Footer_Sec5{
    color: #7280FE;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.023%; /* 22.404px */
}
.link {
    text-decoration: none;
    color: inherit;
  }