@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorCreateCourse_Container{
    display: flex;
    /*padding-bottom: 64px;*/
    flex-direction: column;
    align-items: center;
    gap: 56px;
    width: 100%; 
}
 .Mandatory_star {
  color: crimson;
 }
.InstructorCreateCourse_Sec1{
    display: flex;
    width: 100%;
    padding: 24px 40px;
    justify-content: space-between;
    align-items: center;
    /* Style Text */
    color: #000;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
.InstructorCreateCourse_Sec1_publish_Button {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #18172F;
    /* style teXT*/
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;

}
.Dashboard_FormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.InstructorCreateCourse_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 56px;
   
    padding-bottom: 64px;
}
.InstructorCreateCourse_form_Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 50vw;
}
.InstructorCreateCourse_form_Section_title {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
.InstructorCreateCourse_form_Section_input {
  /*  height: 44px;*/
    border-radius: 10px;
    background: #EDEFFF;
    width: 100%;
    border: none;
    padding: 10px;
    /* Text */
     font-size: 20px;
     font-family: Inter;
     font-weight: 500;
     resize: vertical; /* Allows resizing the textarea vertically */
}
.InstructorCreateCourse_form_Section_True_False{
    display: flex;
    align-items: flex-start;
    gap: 24px;
}
.InstructorCreateCourse_form_button_yes {
    display: flex;
    width: 80px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #D5D9FF;
    /* Style Text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
.InstructorCreateCourse_form_button_no {
    display: flex;
    width: 80px;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #18172F;
    /* style text */
    color: #FFF;    
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
/* Style Calender Picker  */
.Instrector_DateSelector_Container {
    position: relative;
}
.Instrector_DateSelector {
    z-index: 10;
    position: absolute;
    width: 500px;
    right: -290px;
    bottom: 20px;
}
/* Select level about Course */
.course-level-container {
    display: flex;
    flex-direction: column;
  }
  .CourseLevelSelector_Button_content{
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 8px;
  }
  .course-level-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 10px;
  }
  
  .level-button {
    background-color: #D5D9FF;
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 4px 10px;
    border-radius: 10px;
    align-items: center;
    gap: 8px;
    border: 1px solid #D5D9FF;
    /* style text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
  }
  
  .level-button.selected {
    background: #18172F;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
  }
  
  .level-button .icon {
    margin-right: 8px;
  }
  
  .submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3b3b3b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .InstructorCreateCourse_form_Language {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
  /* question section */
  .qna-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .InstructorCreateCourse_form_question_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
 /* Time Session Selector  */
 .time-session-selector {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
}
.time-session-selector img {
  cursor: pointer;
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Duration-Input_Countainer {
  display: flex;
  gap: 5px;
}
.Duration-Input_Countainer input{
  display: flex;
  width: 64px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  text-align: center;  
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #D5D9FF;
  /* Design the Text number inside the input field */
  color: #18172F;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 34.5px */
  letter-spacing: -0.437px;
}
.time-block-title {
  color: #9297BB;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.38px;
}
.course-level-selector {
  display: flex;
  justify-content: flex-start;
  
}

/* Loading Animation */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100px; /* Adjust height as needed */
}

.loading-spinner {
  border: 6px solid #f3f3f3; /* Light gray */
  border-top: 6px solid #3F52FC; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 10px;
  font-size: 1.8rem;
  color: #3F52FC;
  text-align: center;
  animation: fadeIn 1.1s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from { opacity: 0.3; }
  to { opacity: 1; }
}