@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.EnterpriseGallery_LinkAlbum_Container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 36px;
 }
 .EnterpriseGallery_LinkAlbum_images {
     display: flex;
     width: 150px;
     height: 40px;
     padding: 8px 8px;
     justify-content: center;
     align-items: center;
     gap: 8px;
     flex-shrink: 0;
     border-radius: 6px;
     background: #18172F;
     color: #FFF;
     cursor: pointer;
 }

 .EnterpriseGallery_LinkAlbum_images:hover {
    background-color: #18172F;
    color: #9e9797;
  }
  
  .EnterpriseGallery_MainImage_Container {
    max-width: 100%;
    margin-top: 20px;
  }
  
  .EnterpriseGallery_MainImage {
    width: 100%;
    max-width: 600px;
    height: 450px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .EnterpriseGallery_Slider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .SliderArrow {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  .SliderImages {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px;
  }
  .SliderImageContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    align-items: center;
    width: 206px;
  }
  
  .SliderImage {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s;
  }
  
  .SliderImage.Selected {
    border-color: #007bff;
    transform: scale(1.1);
  }
  
  .SliderImage:hover {
    transform: scale(1.1);
  }
  .EnterpriseGallery_Save {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #18172F;
    color: #FFF;
  }
  .DeleteButton {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /* style text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
  }