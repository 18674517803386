@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Container styling */
.update-course-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70vw;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9fc;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
}

/* Heading styling */
.update-course-container h1 {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* Form styling */
.update-course-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Label styling */
.update-course-form label {
    font-size: 16px;
    color: #555;
    margin-bottom: 8px;
    margin-top: 16px;
}

/* Input and textarea styling */
.update-course-input {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    width: 65vw;
    color: #333;
    background-color: #EDEFFF;
    transition: border 0.3s ease, background-color 0.3s ease;
}

.update-course-input:focus {
    outline: none;
    border-color: #6a8efb;
    background-color: #e0e7ff;
}

/* Textarea specific styling */
textarea.update-course-input {
    resize: vertical;
    min-height: 100px;
}

/* Style for preview image */
.update-course-image-preview {
    max-width: 100%;
    border-radius: 8px;
    margin-top: 12px;
}

/* Style for the loading and error messages */
.update-course-container p {
    font-size: 14px;
    color: #ff4d4f;
    text-align: center;
    margin: 5px 0;
}

/* Submit button styling */
.update-course-submit-container {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
}

.update-course-submit-button {
    height: 40px;
    padding: 8px 16px;
    border-radius: 6px;
    width: 15vw;
    background: #18172F;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.update-course-submit-button:hover {
    background-color: #15162e;
}

.update-course-container div {
    margin: 0vw; 
    padding: 5px;
}