/* start to design appbar when user is first time in the web */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.UnauthAppbar_container{
   display: flex;
   flex-direction: column;
   width: 100%;
}
/* this appbar have a Signup Button */
.UnauthAppbar_First_app {
    background: #FFF493;
    display: flex;
    height: 64px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
}
/* Design Arabic appbar*/
.UnauthAppbar_First_app_ar {
    background: #FFF493;
    display: flex;
    flex-direction: row-reverse;
    height: 64px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
}
.UnauthAppbar_First_app_Text{
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    display: flex;
    gap: 5px;
}
.UnauthAppbar_First_app_Text_ar {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}
.UnauthAppbar_First_app_Button_container{
    display: flex;
}
/* Button Sign up style */
.UnauthAppbar_First_app_Button{
    border-radius: 6px;
    background: #18172F;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.UnauthAppbar_First_app_Button_ar {
    border-radius: 6px;
    background: #18172F;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.UnauthAppbar_First_app_Button:hover {
    transform:scale(0.9);
}
/* Second AppBar */
.UnauthAppbar_Second_app {
    background: #FFF;
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: flex-start;
}
.UnauthAppbar_Second_app_ar {
    background: #FFF;
    display: flex;
    padding: 24px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
}
.UnauthAppbar_Second_app_Part2{
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
}
.UnauthAppbar_Second_app_Part2_ar {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    flex-direction: row-reverse;
}
.UnauthAppbar_Second_app_Part3{
    display: flex;
    align-items: flex-start;
    gap: 16px;
}
.search-container {
    position: relative;
    display: inline-block;
  }
  .search-container_ar {
  
  }
.Search{
    display: flex;
    width: 284px;
   /* height: 35px;*/
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 6px 6px 6px 6px;
    background: #EDEFFF;
    /* Font style inside the input Field */
    color: #353646;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    cursor: pointer;
    border:none;
}
.Search:focus {
    border : none ;
    outline: none; /* Remove default outline */
}
.Search_ar {
    display: flex;
    flex-direction: row-reverse;
    direction: rtl;
    width: 284px;
   /* height: 25px;*/
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #EDEFFF;
    /* Font style inside the input Field */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    cursor: pointer;
    border: none;
}
.Search_ar::placeholder{
    color: #3F52FC;}
/* change input field placeholder to blue color  */
/* Increase specificity */
input.Search_ar:focus {
    border: none; /* Remove border on focus */
    outline: none; /* Remove default outline */
  }
  /*
.Search_ar:focus {
    border: none !important;
  } */
  
.Search::placeholder{
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.search-icon {
    position: absolute;
    right: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures the icon doesn't block input interactions */
    height: 20px; /* Adjust icon size as needed */
    width: 20px; /* Adjust icon size as needed */
    cursor: pointer;
  }
  .search-icon-ar {
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Ensures the icon doesn't block input interactions */
    height: 20px; /* Adjust icon size as needed */
    width: 20px; /* Adjust icon size as needed */
    cursor: pointer;
  }
.UnauthAppbar_Second_app_button{
    display: flex;
    padding: 8px 8px 8px 24px;
    align-items: center;
    gap: 8px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; /* 24px */
    letter-spacing: -0.304px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
}
.UnauthAppbar_Second_app_button_ar {
    display: flex;
    flex-direction: row-reverse;
    padding: 8px 8px 8px 8px;
    align-items: center;
    gap: 15px;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%; /* 24px */
    letter-spacing: -0.304px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    cursor: pointer;
}
/* Style Blog in Second Appbar  */
.UnauthAppbar_Second_app_Blog{
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    /* style the text inside this container */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    cursor: pointer;
}
.UnauthAppbar_Second_app_Blog_ar {
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    /* style the text inside this container */
    color: #18172F;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%; /* 30px */
    letter-spacing: 0.38px;
    cursor: pointer;
}
/* Part 3  */
.UnauthAppbar_Second_app_Part3 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    padding-right: 20px;
}
.UnauthAppbar_Second_app_Part3_ar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    padding-right: 20px;
}
.UnauthAppbar_Second_app_Part3_Language{
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* style txt */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    border-radius: 6px;
    background: #EDEFFF;
    cursor: pointer;
}
.UnauthAppbar_Second_app_Part3_Language_ar {
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    /* style txt */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    border-radius: 6px;
    background: #EDEFFF;
    cursor: pointer;
}
/* style login button */
.UnauthAppbar_Second_app_Part3_Login {
    display: flex;
    height: 35px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
}
.UnauthAppbar_Second_app_Part3_Login_ar {
    display: flex;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    border:none;
    text-transform: uppercase;
    cursor: pointer;
}
.UnauthAppbar_Second_app_Part3_Login button {
    cursor: pointer;
    transform:scale(1);
    border: none;
}
.UnauthAppbar_Second_app_Part3_Cart{
    display: flex;
    height: 40px;
    padding: 4px;
    align-items: center;
    gap: 8px;
    /* Style Container Text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    }
    .UnauthAppbar_Second_app_Part3_Cart_ar {
        display: flex;
        flex-direction: row-reverse;
        height: 40px;
        padding: 4px;
        align-items: center;
        gap: 8px;
        /* Style Container Text */
        color: #18172F;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
        letter-spacing: -0.38px;
        position: relative;
    }

    .UnauthAppbar_Second_app_Part3_Cart_ar_number {
        position: absolute;
        top: -10px;
        right: 50%;
        /*Style Text*/
        font-size: 16px;
        color: #242d77;
        font-weight: 500;
    }
    .Language_Arabic {
        font-size: 25px;
        color: #242d77;
        font-weight: 500;
    }