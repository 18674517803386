.Course_Saved_in_Component {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 8px;
      margin-bottom: 5px ;
      gap: 12px;
}
.Course_Saved_in_Component_icons {
    width: 30px;
}
.course-cards-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    padding: 25px;
}

.success-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 61px;
    padding: 15px;
    position: fixed; /* Change to fixed so it stays visible while scrolling */
    top: 0%;
    z-index: 1000;
    background: linear-gradient(135deg, #3b49c7, #3F52FC);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    color: #04FC58; /* Dark green for contrast */
    font-family: 'Poppins', sans-serif; /* Stylish font */
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    animation: slideIn 0.5s ease-in-out, fadeOut 3s 2s forwards;
}
/* Add an icon inside the message */
.success-message::before {
    content: '';
    font-size: 20px;
    margin-right: 10px;
    animation: pop 0.5s ease-in-out;
}

/* Slide-in animation for appearance */
@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Pop effect for the icon */
@keyframes pop {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Fade-out animation for disappearing */
@keyframes fadeOut {
    to {
        opacity: 0;
    }
}