.time-container {
    margin: 20px 0;
  }
  
  .InstructorCreateCourse_form_Section_title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .time-form {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .time-input {
    padding: 10px;
    font-size: 1rem;
    width: 200px;
  }
  
  .time-add-button {
    padding: 10px;
    font-size: 1rem;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .time-add-button:hover {
    background-color: #45a049;
  }
  
  .time-list {
    list-style-type: none;
    padding: 0;
  }
  
  .time-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
  }
  
  .time-delete-button {
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .time-delete-button:hover {
    background-color: #e53935;
  }
  