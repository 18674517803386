@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
/* Start To design Section 8 (Footer) */
.Home_Sec8_Footer_ar{
    display: flex;
    padding: 40px 80px 56px 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    background: #F8F9FF;
    margin-top: 50px;
    
}
.Home_Sec8_Footer_Sec1_ar{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    /* Style Text */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    cursor: pointer;
}
/* footer Icon */
.Home_Sec8_Footer_Sec2_ar{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}
/* Footer List */
.Home_Sec8_Footer_Sec3_ar{
    display: flex;
    align-items: center;
    gap: 40px;
}
.Home_Sec8_Footer_Sec3_ar ul {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 40px;
    /**/
    list-style-type: none;
    color: #7E7E8C;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
letter-spacing: -0.304px;
}
.Home_Sec8_Footer_Sec3_ar ul li:hover {
    cursor: pointer;
    color: #3F52FC;
}
/* Social Media IN Footer */
.Home_Sec8_Footer_Sec4_ar{
    display: flex;
    width: 224px;
    justify-content: center;
align-items: center;
gap: 40px;
}
/* @copy Right Section */
.Home_Sec8_Footer_Sec5_ar{
    color: #7280FE;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.023%; /* 22.404px */
}