@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.ArchiveComponent_Select {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;
}

.ArchiveComponent_Select div {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
   }
  
  .ArchiveComponent_Select div.active {
    border-bottom: 5px solid #3F52FC;
    font-weight: bold;
  }
  

  /*------- Article Archive ----------*/
.ArchiveArticle_Container {
    display: flex;
    padding: 0px 30px;
    align-items: center;
    align-content: center;
    gap: 40px;
    align-self: stretch;
    flex-wrap: wrap;
   }

   .ArchiveCoursesList {
     display: flex;
     padding: 25px;
     justify-content: flex-start;
     align-items: center;
     gap: 25px;
     flex-wrap: wrap;

   }