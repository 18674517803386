@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorHome_Container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 40px;
    gap: 24px;
    flex-shrink: 0;
    margin-bottom: 25px; 
    /* style Text for all Page */
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
} 
.Instructor_Activation {
  color: #5E6EFD;
  font-weight: 700;
}
.InstructorHome_Section1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.InstructorPageHome_Sec1_Part1 {
    display: flex;
    height: 148px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* Image Background */
    border-radius: 9px;
}
.InstructorPage_Sec2_part2_EditInfo {
    display: flex;
    padding: 8px 20px 8px 16px;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 6px;
    background: #EDEFFF;
    font-size: large;
}
.InstructorHome_Sec2_Container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.Line_instractor_Dashboard {
    width: 100%;
    align-self: stretch;
    stroke-width: 1px;
    stroke: #C6CCF9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InstructorHomePage_Sec4 {
    display: flex;
    align-items: flex-start;
    gap: 58px;
    align-self: stretch;
    /* color */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    cursor: pointer;
}
.InstructorHomePage_Sec4 > .active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    border-bottom: 5px solid #3F52FC;
    padding-bottom: 10px;
  }
  .InstructorHome_AddCourse_Button {
    display: flex;
    padding: 8px 16px 8px 16px;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    border-radius: 6px;
    width: 220px;
    max-height: 50px;
    background: #18172F;
    position: absolute;
    left: 80%;
    top: 2%;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
  }
  /***       ****/

  .Courses_Instructor_Container{
    display: flex;    
    justify-content: center;  
     flex-wrap: wrap;
     gap: 1vw;
     margin-top: 50px;
   }


   /* About Instructor Style */
   .About_container{
     display: flex;
     justify-content: center !important;
     align-items: center !important;
     padding-top: 5vh;
   }
   .About_container_Description {
      width: 100vw;
      font-size: 18px;
   }
   .InstructorPage_Sec2_Part1 {
    position: relative; /* Ensure parent container is relative */
    display: inline-block; /* Ensure the div wraps around the image properly */
}

.profile_image_instructor {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
    object-fit: cover;
    border: 5px solid #f0f0f0;
    margin: 20px auto;
    display: block;
    object-fit: cover; /* لضمان تغطية الصورة بالكامل */
}
.CameraIcon {
  position: absolute;
  bottom: 25px; /* ضع الأيقونة في الأسفل */
  right: 15px; /* ضع الأيقونة في اليمين */

  cursor: pointer; /* لإظهار أن الأيقونة قابلة للضغط */
}

/* Hover effects */
.profile_image_instructor:hover {
    transform: scale(1.1);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
    filter: brightness(1); /* Brighten the image on hover */
    cursor: pointer;
}

/* Add the 'Update' text on hover */
.InstructorPage_Sec2_Part1::after {
  
    cursor: pointer;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for better readability */
    padding: 5px 5px;
    border-radius: 20%;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevent interaction */
}

/* Show 'Update' on hover */
.InstructorPage_Sec2_Part1:hover::after {
    opacity: 1; /* Show the text on hover */
}

.InstructorPage_SaveButton {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #18172F;
  /* style text */
  color: #FFF;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.304px;
  text-transform: capitalize;
 }

.InstructorPage_Sec2_part3_button_Container {
  display: flex;
  gap: 15px;
}
.InstructorPage_Sec2_part2_ChangePassword {
  display: flex;
  padding: 8px 24px 8px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #18172F;
  border: none;
  /* Style Text */
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.285px;
  text-transform: capitalize;
}
.InstructorPage_Sec2_part2_RightSide {
     display: flex;
     padding-top: 30px;
     padding-bottom: 30px;
     flex-direction: column;
     justify-content: space-between;
     align-items: flex-end;
}
.InstructorPage_Sec2_part2_RightNumbers {
  display: flex;
  padding: 0px 24px;
  align-items: flex-end;
  gap: 56px;
}
.InstructorPage_Sec2_part2_RightNumbers_Items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* style text */
  color: #18172F;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.304px;
}
.InstructorPage_Sec2_part2_RightSide_Email {
  color: #3F52FC;
  cursor: pointer;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  letter-spacing: -0.38px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  text-transform: capitalize;
}


  /* Media Queries for Larger Screens */
@media (min-width: 768px) {
    .About_container {
      flex-direction: row; /* Row layout for larger screens */
      justify-content: space-between;
      align-items: flex-start;
      padding: 40px;
    }
  
    .About_container_Description {
      max-width: 60%;
      text-align: left;
    }
  
    .About_container_Image {
      width: 30%; /* Adjust size for larger screens */
    }
  }
  
  /* Media Queries for Extra Small Screens (Mobile) */
  @media (max-width: 480px) {
    .About_container {
        flex-direction: column; /* Row layout for larger screens */
        align-items: center;
      }
    .profile_image_instructor {
      width: 50vw; /* Make the image larger on small screens */
    }
    .About_container_Description {
       width: 100%;
       font-size: 14px !important;
       padding-left: 0px !important;
      }
  
    .About_container_Description {
      font-size: 1em;
      max-width: 100%;
    }
    .InstructorHome_Container {
        padding: 0px;
    }
  }

  .Article_Show_component { 
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     gap: 2vw;
     position: relative;
  }

  /* change instructor password */
  .ChangeInstructorPassword_Container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 50%;
    margin: 0 auto;
    background-color: #f9f9ff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .ChangeInstructorPassword_Head {
    display: flex;
    justify-content: center;
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
  }
  
  .ChangeInstructorPassword_Form .InputGroup {
    margin-bottom: 15px;
  }
  
  .ChangeInstructorPassword_Form label {
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
  }
  
  .PasswordInput {
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
  }
  
  .PasswordInput input {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 6px;
    background: #EDEFFF;
    border: none;
    width:500px;     /*  this will be dont constant */
    font-size: 20px;
    outline: none;
  }
  .PasswordInput input::placeholder {
    font-size: 16px;
  }
  
  .ToggleVisibility {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    color: #777;
    right:  0%;
  }
  
  .ForgotPassword {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ForgotPassword a {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.304px;
  }
  .ChangeInstructorPassword_Container_buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
  }
  .SaveChangesButton {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /* Design Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
  }
  
  .SaveChangesButton:hover {

  }
     /*  succus message  */
     .SuccessMessage {
      background-color: #d4edda; /* Light green background */
      color: #155724; /* Dark green text */
      border: 1px solid #c3e6cb; /* Light green border */
      padding: 10px 20px;
      border-radius: 8px; /* Rounded corners */
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for a lifted effect */
      animation: fadeIn 1s ease-out; /* Add fade-in animation */
      display: flex;
      align-items: center;
      justify-content: center;
    }
    

    .ErrorMessage {
      background-color: #f8d7da; /* Light red background */
      color: #721c24; /* Dark red text */
      border: 1px solid #f5c6cb; /* Light red border */
      padding: 10px 20px;
      border-radius: 8px; /* Rounded corners */
      margin-top: 20x;
      font-size: 16px;
      font-weight: bold;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for a lifted effect */
      animation: slideIn 1s ease-out; /* Add slide-in animation */
      display: flex;
      align-items: center;
      justify-content: center;
    }

    
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(10px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    /* --------------------------- otp code Mail verfication -----------  */

     .Otp-Container-page{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 50px;
      }


    .verification-container {
      display: flex;
      padding: 40px 32px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
      margin-top: 50px;
      width: 500px;
    }
    .Otp-info-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      /*text style*/

    }
    .title {
      font-size: 2rem;
      margin-bottom: 10px;
      color: #333;
    }
    
    .subtitle {
      color: #000;
      font-family: Inter;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 37.5px */
      letter-spacing: -0.475px;
    }
    
    .instruction {
      color: #000;

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      letter-spacing: -0.38px;
    }
    
    .code-inputs {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;
      border-radius: 12px;
    
    }
    
    .code-input {
      width: 50px;
      height: 50px;
      text-align: center;
      font-size: 1.5rem;
      border: 2px solid #ddd;
      border-radius: 5px;
      outline: none;
      border-radius: 12px;
      background: #EDEFFF;
      transition: all 0.3s ease-in-out;
      /**/
      color: #3F52FC;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 32px */
      letter-spacing: -0.38px;
      text-transform: capitalize;
    }
    
    .code-input:focus {
      border-color: #4a90e2;
      box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
    }
    
    .timer {
      margin-bottom: 15px;
    }
    
    .time-left {
      color: #3F52FC;

      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 32px */
      letter-spacing: -0.38px;
      text-transform: capitalize;
    }
    .otp-sent-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    
    .resend-btn {
      background: none;
      color: #4a90e2;
      border: none;
      cursor: pointer;
      font-size: 0.9rem;
    }
    
    .resend-btn:disabled {
      color: #bbb;
      cursor: not-allowed;
    }
    
    .send-btn {
      display: flex;
      height: 40px;
      padding: 8px 24px;
      justify-content: flex-end;
      align-items: center;
      border: none;
      gap: 8px;
      border-radius: 6px;
      background: #3F52FC;
      font-size: 1rem;
      transition: background 0.3s;
      /* style text */
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      letter-spacing: -0.304px;
    }
    
    .send-btn:hover {
      background-color: #357abf;
    }
    

    /* ChangeOtpPassword */
    .ChangeOtpPassword_Container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      flex-direction: column;
    }

    .ChangeOtpPassword_Sec1 {
      display: flex;
      padding: 40px 32px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
      width: 600px;
      /* Style Text */
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 30px */
      letter-spacing: -0.38px;
    }
    .ChangeOtpPassword_Sec1_input {
      border-radius: 10px;
      background: #EDEFFF;
      height: 51px;
      width : 70% ;
      padding: 10px ;
      border: none ;
      outline: none; /* Prevent the default focus outline */
    }
    .ChangeOtpPassword_Sec1_input::placeholder {
      font-size: 12px ; /*ust the size as needed */
      color: #888; /* Optional: change placeholder color */
    }