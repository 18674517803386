@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.enterprise-create-course {
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* خلفية بيضاء */
  }
  
  .step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center; /* محاذاة العناصر عمودياً */
    margin: 20px 0;
  }
  
  .step-container {
    display: flex;
    align-items: center;
    flex-direction: row; /* تنظيم العناصر عمودياً */
   justify-content: center;
  }
  
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color: #333;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    width: 50px; /* زيادة الحجم */
    height: 50px; /* زيادة الحجم */
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s; /* انتقال سلس */
  }
  
  .step.active {
    background-color: #3F52FC; /* اللون النشط */
    color: white;
    box-shadow: 0 0 10px rgba(8, 34, 184, 0.5); /* ظل للنقطة النشطة */
    transform: translateY(-5px); /* رفع النقطة النشطة */
  }
  
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px; /* عرض الخط */
    height: 10px; /* ارتفاع الخط */
    background-color: #ddd; /* لون الخط */
    margin: 10px 0; /* مسافة بين الخطوط والنقاط */
  }
  
  .checkmark {
    font-size: 20px; /* حجم علامة الصح */
    color: #fff; /* لون علامة الصح */
    z-index: 10;
  }
  
  .form-content {
    margin: 20px 0;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  

  /* Enterpeise step about form */
  .Enterprise_createCourse_Step1 {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: flex-start; /* لضمان توسيط العناصر عموديًا */
  }

  .EnterpriseCreateCourse_form_Right_Section {
     width: 50%;
     display: flex;
     flex-direction: column;
     gap: 5px;
  }
  .EnterpriseCreateCourse_form_left_Section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .vertical-line {
    width:2px; /* عرض الخط */
    background-color: #635f5fe5; /* لون الخط */
    height: 50vh; /* طول الخط بالكامل */
  }
  .EnterpriseCreateCourse_form_Section_title {
    font-size: larger;
    font-weight: 500;
  }

  /* Error Animation show when inut empty */
  .error-message-enterpriseform {
    color: #ff4d4f;
    background-color: #ffe6e6;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    position: relative;
    animation: fadeInSlide 0.6s ease-out, shakeError 0.6s ease-in-out;
  }
  
  /* Fade-in and slide-down animation */
  @keyframes fadeInSlide {
    0% {
      opacity: 0;
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Shake effect for attention */
  @keyframes shakeError {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(5px);
    }
  }
  
  /* end animation error */
  .Enterprise_Next_Button {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #18172F;
    /* Text Style */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
   }

   .Enterprise_Previous_Button {
    display: flex;
    height: 40px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3E3C66; /* Slightly lighter */
    /* Text Style */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}

/* Design Multi Select */
.language-select-container {
  width: 300px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.selected-languages {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
}

.selected-language {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}

.remove-button {
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
