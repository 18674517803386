@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Signup{
    direction: rtl;
    height: 756px;
    flex-shrink: 0;
}
/* style Signup form header Role */
.Signup_header{
    display: flex;
    width: 236px;
    padding: 9px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    background: #FFF;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
/* Start design Sign Body */
.Signup_body {
    display: flex;
    flex-direction: row-reverse;
}
.Signup_body_Form{
    display: flex;
    width: 560px;
    padding: 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    border-radius: 0px 0px 16px 0px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
   
}
/* Section One */
.Signup_body_Form_Sec1{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    gap: 50px;
}
.Signup_body_Form_Sec2{
    /* style text  */
    color: #18172F;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    width: 100%;
    direction: ltr;
}
.Signup_body_Form_Sec3{
    height: 0px;
    align-self: stretch;
    stroke-width: 1px;
    stroke: #C6CCF9;
    padding: 0px;
    display: none;
}
/* start to style input Field */
.Signup_body_input_container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0px;
    align-self: stretch;
    direction: inherit; /* يتبع الاتجاه من الحاوية الرئيسية */
   
}
.Signup_body_Form_Sec4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    /* style text */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-align: inherit; /* يتبع الاتجاه */
}
.Signup_body_input{
    border-radius: 6px;
    background: #EDEFFF;
    display: flex;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    width: 100%;
    height: auto;
    direction: inherit; /* يتبع اتجاه اللغة */
}
.Sign_input{
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #EDEFFF;
    border: none;
    outline: none; /* يزيل الحدود الافتراضية */
    text-align: inherit; /* يتبع اتجاه اللغة */
    /* Style text inside input Field*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
   }


/* دعم RTL */
.rtl .Signup_body_input_container {
    align-items: flex-end;
    direction: rtl;
}

.rtl .Signup_body_Form_Sec4 {
    align-items: flex-start !important;
    text-align: right;
}

.rtl .Sign_input {
    text-align: right;
}

/* دعم LTR */
.ltr .Signup_body_input_container {
    align-items: flex-start;
    direction: ltr;
}

.ltr .Signup_body_Form_Sec4 {
    align-items: flex-start;
    text-align: left;
}


.ltr .Sign_input {
    text-align: left;
}






   /* Start Section 5  */
.Signup_body_Form_Sec5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* Style Text*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Signup_body_Form_Sec5_button{
    display: flex;
    width: 120px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    cursor: pointer;
    border: none;
    /* Style Text*/
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
}  
/* span === Signup */
.Signup_body_Form_Sec5 span {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    cursor: pointer;
} 
.Loggo_Container {
    display: flex;
}
.Signup_body_Form_Sec6_footer{
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.023%; /* 24.004px */
    text-transform: capitalize;
    direction: ltr;
}
.Signup_body_Form_Sec6_footer span{
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.023%;
    text-decoration-line: underline;
    text-transform: capitalize;
}