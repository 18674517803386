@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
/* Start Design Blog Page  */
.Blog_Container {
    display: flex;
    padding: 24px 40px 0px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    align-self: stretch;
}
@media (max-width: 600px) {
    .Blog_Container {
        display: flex;
        padding: 24px 10px 0px 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 44px;
        align-self: stretch;
    }
}
/* Section One */
.Blog_Sec1{
    display: flex;
    width: 1176px;
    align-items: flex-start;
    gap: 8px;
    justify-content: flex-start;
     
    /* want to style text*/
    color: #7E7E8C;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  /*  line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Blog_Sec2{
    display: flex;
    padding: 30px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
    background: #3F52FC;
}
.Blog_Sec2_P1{
    color: #FFF;
    font-family: Inter;
    font-size: 31.25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 46.875px */
    letter-spacing: -0.594px;
    text-transform: capitalize;
}
.Blog_Sec2_P2{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
    width: 891.986px;
}
@media (max-width: 600px) {
    .Blog_Sec2{
        display: flex;
        padding: 30px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        border-radius: 16px 16px 0px 0px;
        background: #3F52FC;
    }
    .Blog_Sec2_P1{
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 46.875px */
        letter-spacing: -0.594px;
        text-transform: capitalize;
        max-width: 350px;
    }
    .Blog_Sec2_P2{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 46.875px */
        letter-spacing: -0.594px;
        text-transform: capitalize;
        max-width: 300px;
    }
}
.Blog_Sec3{
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 16px 16px;
    background: #EDEFFF;
}
.Blog_Sec3_P1{
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.Wrap_Sec1_sec2{
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;
}
@media (max-width: 600px) {
    .Blog_Sec3{
        display: flex;
        padding: 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 0px 0px 16px 16px;
        background: #EDEFFF;
    }
    .Blog_Sec3_P1{
        color: #3F52FC;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 37.5px */
        letter-spacing: -0.475px;
        text-transform: capitalize;
    }
    .Wrap_Sec1_sec2{
        display: flex;
        flex-direction: column;
        gap: 0px;
        width: 100%;
    }
    .UnauthAppbar_First_app_Button{
         display: none;
    }
}
.Blog_Sec4{
   display: flex;
   align-items: flex-start;
   gap: 58px;
   align-self: stretch;
   cursor: pointer;
   /* To Style Text */
   color: #7E7E8C;
   font-family: Inter;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: 150%; /* 30px */
   letter-spacing: -0.38px;
   text-transform: capitalize;
}
.Blog_Sec4 > .active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    border-bottom: 5px solid #3F52FC;
    padding-bottom: 10px;
  }
  @media (max-width: 600px) {
    .Blog_Sec4{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        align-self: stretch;
        cursor: pointer;
        /* To Style Text */
        color: #7E7E8C;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%; /* 30px */
        letter-spacing: -0.38px;
        text-transform: capitalize;
     }
     .Blog_Sec4 > .active {
         color: #18172F;
         font-family: Inter;
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         line-height: 150%; /* 30px */
         letter-spacing: -0.38px;
         text-transform: capitalize;
         border-bottom: 5px solid #3F52FC;
         padding-bottom: 10px;
       }
       .Blog_Line {
        width: 350px;
       }
  }
/* Ensure Blog_Sec5 has responsive width */
