@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorReview_Container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-self: stretch;
}
.InstructorReview_Sec1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
/* Review word style */
.InstructorReview_Sec1_Part1 {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
/* */
.InstructorReview_Sec1_Part2{
    display: flex;
    align-items: flex-start;
    gap: 16px;
}
.InstructorReview_Sec1_Part2_item{
    display: flex;
    height: 32px;
    align-items: center;
    gap: 4px;
    /* Style text at this Item */
    color: #18172F;
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.38px;
    line-height: 150%; /* 30px */
}