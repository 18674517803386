@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Cart_Container {
    display: flex;
    padding: 24px 40px 0px 40px;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    align-self: stretch;
    height: 100%;
    position: relative;
}
.courseimage_Cart {
    width: 180px;
}
.Cart_Part1 {
    display: flex;
    padding: 0px 56px 0px 24px;             
    align-items: flex-start;
    gap: 56px;
    align-self: stretch;
}
.Cart_Part1_sec1{
    display: flex;
    padding: 24px 0px 32px 0px;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    flex: 1 0 0;
    background: #FFF;
}
.Cart_Part1_sec1_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
   
}
.Cart_Part1_sec1_header_R {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
.Cart_Part1_sec1_header_R2 {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-decoration-line: underline;
    text-transform: capitalize;
}
/* payment Cart */
.Cart_Part1_sec2 {
    display: flex;
    width: 342px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 12px;
    background: #FFF;
/* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.Cart_Part1_sec2_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.Cart_Part1_sec2_price_total {
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Cart_Part1_sec2_price_totalNum {
    color: #3F52FC;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.Cart_Part1_sec2_discount_totalNum{
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Cart_Part1_sec2_discount {
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Cart_Part1_sec2_checkout {
    display: flex;
    height: 64px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: #18172F;
    /* Start Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
    cursor: pointer;
}
.cart_line {
    stroke-width: 1px;
    stroke: #C6CCF9;
    width: 90%;

}
.Cart_Part1_sec1_body {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.Cart_Part1_sec1_body_course{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    
}
.Cart_Part1_sec1_body_course_part1 {
    display: flex;
    align-items: flex-start;
    gap: 32px;
}
.Cart_Part1_sec1_body_course_part1_info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.Cart_Part1_sec1_body_course_part1_coursename {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.Cart_Part1_sec1_body_course_part1_instructorname {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Cart_Part1_sec1_body_course_part1_clock {
    display: flex;
    align-items: center;
    gap: 8px;
    /* style Text */
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Cart_Part1_sec1_body_course_part2 {
    display: flex;
    height: 124px;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.Cart_Part1_sec1_body_course_part2_price {
    display: flex;
    gap: 2px;
    align-items: center;
    color: #3F52FC;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    /*line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Cart_Part1_sec1_body_course_part2 img {
    cursor: pointer;
}
/* pop up delete yes Or Cansel */


.Cart_Delete {
    position: absolute;
    top: 10%;
    right: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 609px;
    padding: 16px 24px;
    gap: 29px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 16px rgba(167, 173, 229, 0.40);
    z-index: 1000; /* Ensure it's above other content */
}
/*
.Cart_Delete {
    display: flex;
    width: 609px;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 29px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
    position: absolute;
    top: 50;
    left: 50;
}
*/
.Cart_Delete_Question {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.Cart_Delete_Answer {
    color: #18172F;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.Cart_Delete_Button {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.Cart_Delete_Button_cancel{
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #EDEFFF;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
    border: none;
}
.Cart_Delete_Button_Delete {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    background: #FF5353;
    border: none;
    /* style text*/
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
}
.Cart_Delete_Exit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.Cart_Part1_sec2_discountcode {
    display: flex;
    align-items: center;
    gap: 8px;
    /*style text */
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
.Cart_Part1_sec2_discountInput {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}
.Cart_Part1_sec2_discountInputfield {
    display: flex;
    width: 174px;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #EDEFFF;
    border: none;
    outline: none;
    /* Text */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.Cart_Part1_sec2_discount_button {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /*style text*/
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    cursor: pointer;
}