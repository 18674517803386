@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.ArticleById_Container{
    display: flex;
    padding-top: 24px;
    flex-direction: column;
/*    align-items: center;*/
    gap: 5vh;
}
.ArticleById_Header{
    color: #18172F;
    font-family: Inter;
    font-size: 31.25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 46.875px */
    letter-spacing: -0.594px;
    text-transform: capitalize;
   /*padding-top: 50px;*/
}
.ArticleById_Sec1{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}
.ArticleById_Sec2 {
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
}
.ArticleById_Sec2_Name {
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
}
.ArticleById_Sec2_Date {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.ArticleById_Sec3 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ArticleById_Sec4 {
    display: flex;
    flex-direction: column;
    padding-left: 18%;
    gap: 24px;
}
.ArticleById_Sec4_Title {
    display: flex;
    justify-content: flex-start;
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.304px;
}
.ArticleById_Sec4_Paraghraph {
    width: 70vw;
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 5vh;
    letter-spacing: -1.9%;
}
.Article_Image_From_Api {
    border-radius:15px;
    width:60vw ;
}
/*** Responsive Design  ***/
/* Media queries to adjust for different screen sizes */
@media (max-width: 768px) {

  }
        /* media query for mobile devices */
  @media (max-width: 480px) {
    .ArticleById_Sec4_Paraghraph {
        width: 75vw;
        font-family: Inter;
        font-weight: 500;
        font-size: 16px;
        line-height: 4vh;
        letter-spacing: -1.9%;
    }
    .Article_Image_From_Api {
        border-radius:15px;
        width:95vw ;
    }
  }