@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.InstructorPage_Continer {
    display: flex;
    padding-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
}
  /* Instructor Header */
.InstructorPage_Header {
    display: flex;
    padding: 0px 40px;
    align-items: flex-start;
    gap: 8px;
    /* Stele the text */
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
/* Style Section One */
.InstructorPage_Sec1 {
    display: flex;
    padding: 0px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.InstructorPage_Sec1_Part1 {
    display: flex;
    height: 148px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    /* Image Background */
    border-radius: 9px;
    background: url('../../assets/images/instructors/InstructorBackground.svg') lightgray 50% / cover no-repeat ;
}
.InstructorPage_Sec1_Part2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}
.InstructorPage_Sec2 {
    display: flex;
    align-items: flex-start;
    gap: 32px;
}
.InstructorImage {
    width: 148px;
    height: 148px;
    border-radius: 148px;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.InstructorPage_Sec2_part2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}
.InstructorPage_Sec2_part2_Button{
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: none;
    background: #3F52FC;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
    cursor: pointer;
}
.InstructorPage_Sec2_part2_name {
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;
}
.InstructorPage_Sec2_part2_Major {
    color: #5E6EFD;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.InstructorPage_Sec2_part2_JoinDate {
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.InstructorPage_Sec3{
    display: flex;
    height: 163px;
    padding-top: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.InstructorPage_Sec3_Part1 {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-decoration-line: underline;
    text-transform: capitalize;
    cursor: pointer;
}
.InstructorPage_Sec3_Part2{
    display: flex;
    padding: 0px 24px;
    align-items: flex-end;
    gap: 56px;
    border-radius: 8px;
}
.InstructorPage_Sec3_Part2_item {
    display: flex;
    width: 74px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    /* number */
    color: #18172F;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
}
.InstructorPage_Sec3_Part2_item span {
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
.InstructorPage_Sec4{
    display: flex;
    align-items: flex-start;
    gap: 58px;
    align-self: stretch;
}
.InstructorPage_Sec4{
    display: flex;
    align-items: flex-start;
    gap: 58px;
    align-self: stretch;
/* color */
color: #7E7E8C;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 30px */
letter-spacing: -0.38px;
text-transform: capitalize;
cursor: pointer;
}

.InstructorPage_Sec4 > .active {
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
    border-bottom: 5px solid #3F52FC;
    padding-bottom: 10px;
  }
.InstructorPage_Sec5 {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 40px;
    align-self: stretch;
    flex-wrap: wrap;
}