.Article_Instructor_picture {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.scroll-progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 8px;
    background-color: #3F52FC;
    transition: width 1s ease-in-out;
    z-index: 1000;
  }

  .ArticleById_Sec4_share{
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;  
    /* Design Text */
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
   }