@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.Login_page_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   /* padding: 20px 15px 20px 15px;    */
    gap: 12px;
}

 .Login_body_input_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    align-self: stretch;
    direction: ltr;
}
.Login_page_DropDown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 25px;
}
.ROle {
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.304px;
}
.Login_page_DropDown label {
    margin-bottom: 0px;
}

select {
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    cursor: pointer;
}
.Login_page_hidden {
    display: block;
}

.alert {
    margin-top: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #4caf50; /* Green background */
    color: white; /* White text */
    border: 1px solid #4caf50; /* Green border */
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0; /* Start hidden */
    transform: translateY(-20px); /* Start slightly above */
    animation: slideIn 0.5s forwards;
}
.Error_message {
    color: rgba(233, 47, 47, 0.822);
    font-size:18px;
    font-weight: 500;
    padding: 0px;
    width: 450px;
    position: relative;
}
@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(10);
    }
}
 /* Signup Section Style*/
.Signup_page_Image {
    display: none;
}
.SignUP_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Registrition_Body{
    display: flex;
}

.Registrition_Body_forget {
    display: flex;
    justify-content: center;
    align-items: center;
}



.Signup_body_Form_FirstAndLast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
   /* gap:30px;  */
   /* style text */
   color: #18172F;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: 150%; /* 24px */
   letter-spacing: -0.304px;  
}
.Signup_body_input_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.Registration_body_Form_Sec5{
    display: flex;   
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    /* style Text*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;  
}
/* instructor =========> Student  */
.Instructor_Student_Container_login {
    display: flex;
    gap: 24px;
    width: 397px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    position: relative;
    bottom:77px;
    right: 10%;
    /* Text Design */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px
}
.Instructor_Student_Container_login h6 {
    cursor: pointer;
    display: inline-block;
    padding-top: 5px;
  }
.active {
    color:#3F52FC;
    font-weight: 700;
  }