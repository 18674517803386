@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
.Instructor_List_Container {
    display: flex;
    gap: 50px;
}
.Instructor_Container {
    display: flex;
    width: 248px;
    min-width: 248px;
    padding: 16px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    /**/
    border-radius: 12px;
    background: #FFF;
    /* lndex drop shadow */
    box-shadow: 0px 0px 16px 0px rgba(167, 173, 229, 0.30);
}
.Instructor_Container:hover {
    box-shadow: 2px 5px 5px 0px rgba(167, 173, 229, 0.30);
}
.Instructor_Sec2{
    color: #18172F;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: capitalize;
}
.Instructor_Sec3{
    color: #5E6EFD;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: capitalize;
}
.Instructor_Sec4 {
    display: flex;
    align-items: center;
    gap: 4px;
    /* Style Text*/
    color: #18172F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
}
/* section 5 style button */
.Instructor_Sec5 button {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #3F52FC;
    border: none;
    /* style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
}
.Instructor_Sec5 button:hover {
    background: #97a0ea;
    cursor: pointer;
}
.Instructor_Profile_Picture {
    width: 150px;
    height: 150px; /* Ensure height matches width for a perfect circle */
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Adds a soft shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    object-fit: cover; /* Ensures the image covers the circle without distortion */
}

.Instructor_Profile_Picture:hover {
    transform: scale(1.05); /* Scale up the image on hover */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Enhance the shadow on hover */
}

.popup {
    position: fixed; /* Sit on top of the page content */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.6); /* Black background with transparency */
    display: flex; /* Center the popup */
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff; /* White background for the popup */
    padding: 30px;
    font-size: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative; /* Allows absolute positioning of the close button */
    display: flex;
    width: 609px;
    padding: 20px 24px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    color: #18172F;
}
.Popup_Message {
    margin-top: 20px;
    color: #18172F;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 37.5px */
    letter-spacing: -0.475px;
    text-transform: capitalize;

}
.popup_button_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: 100%;
}

.popup_button_Login {
    display: flex;
    padding: 8px 24px;
    width: 250px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border: none;
    border-radius: 6px;
    background: #EDEFFF;
  /* Style Text */
    color: #3F52FC;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px; ;
    text-transform: uppercase;
}
.popup_button_Signup {
    display: flex;
    padding: 8px 24px;
    width: 250px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border: none;
    border-radius: 6px;
    background: #3F52FC;
    /* Style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px;
    text-transform: uppercase;
}





.close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer; /* Pointer cursor for close button */
    font-size: 35px;
    color: #3F52FC;
}
.popup-content .pop_sign{
    font-size: 18px;
    text-decoration: underline;
    color: #18172F;
    cursor: pointer;
}
/* Unfoolow Button */
.Instructor_Sec5_Unfollow button{
    background: #18172F;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: none;
    /* style Text */
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.38px;
    text-transform: uppercase;
}