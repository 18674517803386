.Instructor_Student_Container {
    display: flex;
    width: 236px;
    padding: 9px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    background: #f8f3f3;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;
    top: -50px;
    left: 25px;
    /* Text Design */
    color: #7E7E8C;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.304px
}
.Instructor_Student_Container h6 {
    cursor: pointer;
    display: inline-block;
  }
.active {
    color:#3F52FC;
    font-weight: 700;
  }

  .CityDrop_Signup {
    color: bisque;
    height: 250px !important;
  }
    /* Design Enterprise Forms and button  */
  .EnterpriseForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 10px;

  }

/* دعم اتجاه LTR */
.EnterpriseForm.ltr {
  text-align: left;
}

  .Sign_button_Enterprise {

  }